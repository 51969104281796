<template>
  <div class="main d-flex flex-column justify-center align-center">
    <div class="text">
      <div class="f-s-24 f-w-900" v-html="layouts.data.title"></div>
    </div>
    <div class="text">Please input your email to continue.</div>
    <div class="card">
      <v-icon color="var(--theme_primary)" size="80">mdi-email-outline</v-icon>

      <ValidationObserver ref="observer" class="email">
          <ValidationProvider
            name="Email"
            rules="required|email"
            v-slot="{ errors }"
          >
            <v-text-field
              class="email"
              label="Email"
              :error-messages="errors[0]"
              @keydown.enter="clickContinue"
              v-model="dataSources.current.email"
            ></v-text-field>
          </ValidationProvider>
        </ValidationObserver>
    </div>

      <div class="d-flex width-full">
        <v-spacer></v-spacer>
        <web-btn
          class="mr-5"
          :height="50"
          width="30%"
          @click="clickContinue"
        >
          Continue
        </web-btn>
      </div>
  </div>
</template>

<script>
import { OnlineApi } from '@/api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Tool } from '@/assets/js/util'
import WebBtn from '@/components/base/WebBtn'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    WebBtn
  },
  data () {
    return {
      assists: {
        tool: new Tool()
      },
      dataSources: {
        current: {
          email: ''
        }
      },
      layouts: {
        data: {
          title: ''
        }
      }
    }
  },
  watch: {
    'store.getters.getPortfolioJson': {
      handler: function (val) {
        if (this.assists.tool.isNotEmpty(val)) {
          this.layouts.data.title = 'Welcome to ' + '<br>' + val.name + '!'
        }
      },
      deep: true
    }
  },
  mounted () {
    const portfolio = this.$store.getters.getPortfolioJson
    if (this.assists.tool.isNotEmpty(portfolio)) this.layouts.data.title = 'Welcome to ' + '<br>' + portfolio.name + '!'
    this.$store.commit('setRedirectUrlParameter', this.$route.path)
  },
  methods: {
    clickContinue () {
      this.$refs.observer.validate().then((validResult) => {
        if (validResult) {
          OnlineApi.authenticateIdentity(
            {
              identifier: this.$route.params.identifier,
              email: this.dataSources.current.email
            },
            (res) => {
              this.$store.commit('setCouponDetails', res.couponDetails)
              this.$store.commit('setCouponCode', res.couponCode)
              this.$store.commit('setCollectionLoanNo', res.loanNo)
              this.$store.commit('setCollectionPortfolioId', res.portfolioId)
              OnlineApi.checkCollectionStatus(
                {
                  loanNo: res.loanNo,
                  targetStatus: 205001
                },
                () => {
                  this.$router.push('/coupon/overview')
                },
                (err) => {
                  this.setFailureMessage(err)
                }
              )
            },
            (err) => {
              this.setFailureMessage(err)
            }
          )
        }
      })
    },
    setFailureMessage (err) {
      this.$store.commit('setPopupInformation', {
        message: err.message
      })
    }
  }
}
</script>

<style lang='sass' scoped>
.main
  width: 560px
  margin: 0 auto
  & .text
    font-size: 16px
    align-self: flex-start
  & .card
    display: flex
    flex-direction: column
    width: 80%
    padding: 20px 40px
    margin: 50px 0
    background-color: #f4f5f9 !important
    color: #464C5B
    border-radius: 10px

.email
  width: 100%
  margin: 10px 0
</style>
